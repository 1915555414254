import axios from "axios";
import store from "@/store";

const apiClient = axios.create({
  baseURL: `${process.env.VUE_APP_DOTNET_API_URL}`,
});

apiClient.interceptors.request.use(
  function (config) {
    // 在发送请求之前做些什么
    config.headers["X-Shop-ID"] = store.state.shopID;
    return config;
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

export default apiClient;

//************************************ */
// ADMIN USER LOGIN
//************************************ */

export async function adminLogin(username, password) {
  try {
    const response = await apiClient.post(`/api/CMS/login`, {
      username,
      password,
    });
    console.log("response: " + response.data);
    console.log("登录成功");
    return response;
  } catch (error) {
    // 处理登录失败的情况
    console.error("登录失败:", error);
    return false;
  }
}

export async function checkToken(token) {
  try {
    const response = await apiClient.post(`/api/CMS/CheckToken`, token);
    console.log("Check token result in VUE API Page: " + response.data);
    return response.data;
  } catch (error) {
    console.error(`Token Not Fount, VUE API`, error);
    throw error;
  }
}

//**************************************/
// GOODSTYPE API
//**************************************/

export async function listGoodsTypes() {
  try {
    const response = await apiClient.get(`/api/CMS/goodstype`);
    return response.data;
  } catch (error) {
    console.error(`List GoodsType API request failed: `, error);
    throw error;
  }
}

export async function getGoodsType(id) {
  try {
    const response = await apiClient.get(`/api/CMS/goodstype/${id}`);
    return response.data;
  } catch (error) {
    console.error(`Get GoodsType API request failed: `, error);
    throw error;
  }
}

export async function addGoodsType(typeName) {
  try {
    const response = await apiClient.post(`/api/CMS/goodstype`, typeName);
    return response.data;
  } catch (error) {
    console.error(`Add GoodsType API request failed: `, error);
    throw error;
  }
}

export async function updateGoodsType(typeName) {
  try {
    const response = await apiClient.put(`/api/CMS/goodstype`, typeName);
    return response.data;
  } catch (error) {
    console.error(`Update GoodsType API request failed: `, error);
    throw error;
  }
}

export async function deleteGoodsType(id) {
  try {
    const response = await apiClient.delete(`/api/CMS/goodstype/${id}`);
    return response.data;
  } catch (error) {
    console.error(`Delete GoodsType API request failed: ${id}`, error);
    throw error;
  }
}

//**************************************/
// ORDER ITEMS API
//**************************************/

export async function ListOrderItems(startDate, endDate) {
  try {
    const response = await apiClient.get(`/api/CMS/listOrderItems`, {
      params: {
        startDate: startDate,
        endDate: endDate
      }
    });
    return response.data;
  } catch (error) {
    console.error("Fetch List Order Items API request failed:", error);
    throw error;
  }
}

export async function deleteOrderItems(id) {
  try {
    const response = await apiClient.delete(`/api/CMS/orderItems/${id}`);
    return response.data;
  } catch (error) {
    console.error(`Delete Order Items API request failed: ${id}`, error);
    throw error;
  }
}

export async function updateOrderItems(orderData) {
  try {
    //console.log("id: " + id);
    console.log("Update goodsData: " + orderData);
    console.log("update order items by JSON String, VUE CMSApi: " + JSON.stringify(orderData));
    const response = await apiClient.put(`/api/CMS/orderItems/`, orderData);
    return response.data;
  } catch (error) {
    console.error("Update Order Item API request failed:", error);
    throw error;
  }
}

//**************************************/
// GOODS API
//**************************************/

export async function listGoods() {
  try {
    const response = await apiClient.get(`/api/CMS/goods`);
    return response.data;
  } catch (error) {
    console.error("Fetch Goods API request failed:", error);
    throw error;
  }
}

export async function getGoods(id) {
  try {
    const response = await apiClient.get(`/api/CMS/goods/${id}`);
    return response.data;
  } catch (error) {
    console.error(`Fetch Goods by ID API request failed: ${id}`, error);
    throw error;
  }
}

export async function addGoods(goodsData) {
  try {
    console.log("Create goodsData, VUE CMSApi: " + JSON.stringify(goodsData));
    const response = await apiClient.post(`/api/CMS/goods`, goodsData);
    return response.data;
  } catch (error) {
    console.error("Create Goods API request failed:", error);
    throw error;
  }
}

export async function updateGoods(goodsData) {
  try {
    //console.log("id: " + id);
    console.log("Update goodsData: " + goodsData);
    console.log("update goods by JSON String, VUE CMSApi: " + JSON.stringify(goodsData));
    const response = await apiClient.put(`/api/CMS/goods/`, goodsData);
    return response.data;
  } catch (error) {
    console.error("Update Goods API request failed:", error);
    throw error;
  }
}

export async function deleteGoods(id) {
  try {
    const response = await apiClient.delete(`/api/CMS/goods/${id}`);
    return response.data;
  } catch (error) {
    console.error(`Delete Goods API request failed: ${id}`, error);
    throw error;
  }
}

//************************************ */
// UPLOAD FILE
//************************************ */

export async function uploadPhoto(file) {
  const newFileName = file.name.replace(/\s+/g, "_");

  // 创建一个新的 File 对象
  const newFile = new File([file], newFileName, { type: file.type });

  const formData = new FormData();
  formData.append("file", newFile);

  try {
    const response = await apiClient.post(`/api/CMS/upload`, formData);
    console.log("photo new file name in VUE API Page: " + response.data.newFileName);
    return response.data.newFileName;
  } catch (error) {
    console.error("File upload error:", error);
  }
}

export async function delPhoto(file) {
  try {
    console.log("Del file name in VUE API Page: " + file);
    const response = await apiClient.delete(`/api/CMS/upload/${file}`);
    return response.data;
  } catch (error) {
    console.error("File del error:", error);
  }
}

//**************************************/
// CONTENT-PAGES API
//**************************************/

export async function listContentPages() {
  try {
    const response = await apiClient.get(`/api/CMS/contentPages`);
    return response.data;
  } catch (error) {
    console.error("Fetch Goods API request failed:", error);
    throw error;
  }
}

export async function getContentPages(id) {
  try {
    const response = await apiClient.get(`/api/CMS/contentPages/${id}`);
    return response.data;
  } catch (error) {
    console.error(`Fetch Goods by ID API request failed: ${id}`, error);
    throw error;
  }
}

export async function addContentPages(contentPages) {
  try {
    console.log("Create Page Data: " + JSON.stringify(contentPages));
    const response = await apiClient.post(`/api/CMS/contentPages`, contentPages);
    return response.data;
  } catch (error) {
    console.error("Create new Contents API request failed:", error);
    throw error;
  }
}

export async function updateContentPages(contentPages) {
  try {
    //console.log("id: " + id);
    console.log("Update goodsData: " + contentPages);
    console.log("update Content Pages by JSON String, VUE CMSApi: " + JSON.stringify(contentPages));
    const response = await apiClient.put(`/api/CMS/contentPages/`, contentPages);
    return response.data;
  } catch (error) {
    console.error("Update Content Page API request failed:", error);
    throw error;
  }
}

export async function deleteContentPages(id) {
  try {
    const response = await apiClient.delete(`/api/CMS/contentPages/${id}`);
    return response.data;
  } catch (error) {
    console.error(`Delete Content Pages API request failed: ${id}`, error);
    throw error;
  }
}

//**************************************/
// MEDIA LIB API
//**************************************/

export async function listMediaLib() {
  try {
    const response = await apiClient.get(`/api/CMS/mediaLib`);
    return response.data;
  } catch (error) {
    console.error(`List Media Lib API request failed: `, error);
    throw error;
  }
}

export async function UpdateMediaLib(newMediaLibData) {
  try {
    console.log("Update Media Lib: " + newMediaLibData);
    console.log("update Media Pages by JSON String, VUE CMSApi: " + JSON.stringify(newMediaLibData));
    const response = await apiClient.put(`/api/CMS/mediaLib`, newMediaLibData);
    return response.data;
  } catch (error) {
    console.error(`List Media Lib API request failed: `, error);
    throw error;
  }
}

//**************************************/
// PROMOTION EVENT API
//**************************************/

export async function listPromotionEvent() {
  try {
    const response = await apiClient.get(`/api/CMS/PromotionEvent`);
    return response.data;
  } catch (error) {
    console.error("Fetch Promotion Event API request failed:", error);
    throw error;
  }
}

export async function getPromotionEvent(id) {
  try {
    const response = await apiClient.get(`/api/CMS/PromotionEvent/${id}`);
    return response.data;
  } catch (error) {
    console.error(`Fetch Promotion Event by ID API request failed: ${id}`, error);
    throw error;
  }
}

export async function addPromotionEvent(promotionEvent) {
  try {
    console.log("Create Page Data: " + JSON.stringify(promotionEvent));
    const response = await apiClient.post(`/api/CMS/PromotionEvent`, promotionEvent);
    return response.data;
  } catch (error) {
    console.error("Create new Promotion Event API request failed:", error);
    throw error;
  }
}

export async function updatePromotionEvent(promotionEvent) {
  try {
    //console.log("id: " + id);
    console.log("Update goodsData: " + promotionEvent);
    console.log("update Content Pages by JSON String, VUE CMSApi: " + JSON.stringify(promotionEvent));
    const response = await apiClient.put(`/api/CMS/PromotionEvent/`, promotionEvent);
    return response.data;
  } catch (error) {
    console.error("Update Promotion Event API request failed:", error);
    throw error;
  }
}

export async function deletePromotionEvent(id) {
  try {
    const response = await apiClient.delete(`/api/CMS/PromotionEvent/${id}`);
    return response.data;
  } catch (error) {
    console.error(`Delete Promotion Event API request failed: ${id}`, error);
    throw error;
  }
}
